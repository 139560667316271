<template>
  <div class="options">
    <el-form
      label-position="left"
      label-width="150px"
    >
      <el-form-item label="Office">
        <el-select v-model="office">
          <el-option
            v-for="item in options.office.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Show Ticket Request">
        <el-switch v-model="showTicket" />
      </el-form-item>
      <el-form-item label="Show Calendar">
        <el-switch v-model="showCalendar" />
      </el-form-item>
      <el-collapse-transition>
        <div v-if="showCalendar">
          <el-form-item label="Calendar Text">
            <input
              v-model="calendarText"
              type="text"
              placeholder="Select"
              class="el-input__inner"
            >
          </el-form-item>
          <el-form-item label="Calendar Link">
            <input
              v-model="calendarLink"
              type="text"
              placeholder="Select"
              class="el-input__inner"
            >
          </el-form-item>
        </div>
      </el-collapse-transition>
      <el-form-item label="Show Banner">
        <el-switch v-model="showBanner" />
      </el-form-item>
      <el-collapse-transition>
        <div v-if="showBanner">
          <el-form-item label="Banner Selection">
            <el-select v-model="bannerSelection">
              <el-option
                v-for="item in options.banner.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-collapse-transition>
    </el-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: '',

  data () {
    return {
    }
  },

  computed: {
    ...mapState(['options']),
    showTicket: {
      get () {
        return this.options.ticket.show
      },
      set (v) {
        this.$store.dispatch('updateOptions', { ticket: { show: v } })
      }
    },
    showBanner: {
      get () {
        return this.options.banner.show
      },
      set (v) {
        this.$store.dispatch('updateOptions', { banner: { show: v } })
      }
    },
    bannerSelection: {
      get () {
        return this.options.banner.default
      },
      set (v) {
        this.$store.dispatch('updateOptions', { banner: { default: v } })
      }
    },
    showCalendar: {
      get () {
        return this.options.calendar.show
      },
      set (v) {
        this.$store.dispatch('updateOptions', { calendar: { show: v } })
      }
    },
    calendarText: {
      get () {
        return this.options.calendar.text
      },
      set (v) {
        this.$store.dispatch('updateOptions', { calendar: { text: v } })
      }
    },
    calendarLink: {
      get () {
        return this.options.calendar.link
      },
      set (v) {
        this.$store.dispatch('updateOptions', { calendar: { link: v } })
      }
    },
    office: {
      get () {
        return this.options.office.selected
      },
      set (v) {
        this.$store.dispatch('updateOptions', { office: { selected: v } })
      }
    }
  }
}
</script>

<style label="scss">
.options {
  margin-top: 30px;
}
</style>
